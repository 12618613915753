import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Plot from 'react-plotly.js';

const GraphModal = ({mid_spread, buy_spread, sell_spread, tickers, title, xLabel, yLabel, histPremDiscRtd, setOpenGraphModal}) => {

  const handleClose = () => {
    setOpenGraphModal(false);
  };

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      width: "800px",
      height: "800px",
      backgroundColor: "#303030",
      zIndex: 9999,
      display: "flex",
      flexDirection: "column",
      border: "2px solid black"}}>

      {/* Close Button */}
      <div style={{position: "absolute", top: "5px", right: "5px", cursor: "pointer", color: "white", fontSize: "20px"}} onClick={handleClose}>
        &times;
      </div>

      <div style={{flex: 1, padding: 20, float: 'left', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Tabs style={{marginTop: 30}}>
          <TabList>
            <Tab>Spread</Tab>
            <Tab>Prem/Disc</Tab>
            {tickers?.map(ticker => (
              <Tab>{ticker}</Tab>
            ))}
          </TabList>
          <TabPanel>
            <Plot
              data={
              [{
                x: Array.from({ length: mid_spread?.length }, (_, i) => i + 1),
                y: mid_spread,
                type: 'scatter',
                mode: 'lines',
                name: 'Mid',
                hoverinfo: "text+skip",
                text: mid_spread?.map((_, i) => 
                `${mid_spread?.[i]}%`),
                line: 1
              },{
                x: Array.from({ length: buy_spread?.length }, (_, i) => i + 1),
                y: buy_spread,
                type: 'scatter',
                mode: 'lines',
                name: 'Buy',
                hoverinfo: "text+skip",
                text: buy_spread?.map((_, i) => 
                `${buy_spread?.[i]}%`),
                line: 1
              },{
                x: Array.from({ length: sell_spread?.length }, (_, i) => i + 1),
                y: sell_spread,
                type: 'scatter',
                mode: 'lines',
                name: 'Sell',
                hoverinfo: "text+skip",
                text: sell_spread?.map((_, i) => 
                `${sell_spread?.[i]}%`),
                line: 1
              }]
              }
                layout={ 
                {
                  title: {
                    text: title,
                    font: {
                      size: 24
                    }
                  },
                  width: 675, 
                  height: 650, 
                  showlegend: true,
                  xaxis: {
                    title: xLabel, 
                    zeroline: false,
                    showline: false
                  }, 
                  yaxis: {
                    title: yLabel,
                    zeroline: false,
                    showline: false
                  },
                  legend: {
                    orientation: "h", 
                    y: 1.1,
                  },
                } 
              }
            />
          </TabPanel>
          <TabPanel>
            <Plot
              data={[
                ...tickers?.map(ticker => {
                  return {
                    x: Array.from({ length: histPremDiscRtd?.[ticker]?.mid?.getArray()?.length }, (_, i) => i + 1),
                    y: histPremDiscRtd?.[ticker]?.mid?.getArray()?.map(e => parseFloat((e*100)?.toFixed(2))),
                    type: 'scatter',
                    mode: 'lines',
                    name: ticker,
                    hoverinfo: "text+skip",
                    text: histPremDiscRtd?.[ticker]?.mid?.getArray()?.map((_, i) => 
                    `${(histPremDiscRtd?.[ticker]?.mid?.getArray()?.[i]*100)?.toFixed(2)}%`),
                    line: 1
                  } 
                })]
              }
                layout={ 
                {
                  title: {
                    text: "Prem/Disc",
                    font: {
                      size: 24
                    }
                  },
                  width: 675, 
                  height: 650, 
                  showlegend: true,
                  xaxis: {
                    title: 'Timestep', 
                    zeroline: false,
                    showline: false
                  }, 
                  yaxis: {
                    title: 'Prem/Disc (%)',
                    zeroline: false,
                    showline: false
                  },
                  legend: {
                    orientation: "h", 
                    y: 1.1,
                  },
                } 
              }
            />
          </TabPanel>
          {tickers?.map(ticker => (
            <TabPanel>
              <Plot
                data={
                [{
                  x: Array.from({ length: histPremDiscRtd?.[ticker]?.bid?.getArray()?.length }, (_, i) => i + 1),
                  y: histPremDiscRtd?.[ticker]?.bid?.getArray()?.map(e => parseFloat((e*100)?.toFixed(2))),
                  type: 'scatter',
                  mode: 'lines',
                  name: 'Bid',
                  hoverinfo: "text+skip",
                  text: histPremDiscRtd?.[ticker]?.bid?.getArray()?.map((_, i) => 
                  `${(histPremDiscRtd?.[ticker]?.bid?.getArray()?.[i]*100)?.toFixed(2)}%`),
                  line: 1
                },
                {
                  x: Array.from({ length: histPremDiscRtd?.[ticker]?.mid?.getArray()?.length }, (_, i) => i + 1),
                  y: histPremDiscRtd?.[ticker]?.mid?.getArray()?.map(e => parseFloat((e*100)?.toFixed(2))),
                  type: 'scatter',
                  mode: 'lines',
                  name: 'Mid',
                  hoverinfo: "text+skip",
                  text: histPremDiscRtd?.[ticker]?.mid?.getArray()?.map((_, i) => 
                  `${(histPremDiscRtd?.[ticker]?.mid?.getArray()?.[i]*100)?.toFixed(2)}%`),
                  line: 1
                },
                {
                  x: Array.from({ length: histPremDiscRtd?.[ticker]?.ask?.getArray()?.length }, (_, i) => i + 1),
                  y: histPremDiscRtd?.[ticker]?.ask?.getArray()?.map(e => parseFloat((e*100)?.toFixed(2))),
                  type: 'scatter',
                  mode: 'lines',
                  name: 'Ask',
                  hoverinfo: "text+skip",
                  text: histPremDiscRtd?.[ticker]?.ask?.getArray()?.map((_, i) => 
                  `${(histPremDiscRtd?.[ticker]?.ask?.getArray()?.[i]*100)?.toFixed(2)}%`),
                  line: 1
                }]
                }
                  layout={ 
                  {
                    title: {
                      text: ticker,
                      font: {
                        size: 24
                      }
                    },
                    width: 675, 
                    height: 650, 
                    showlegend: true,
                    xaxis: {
                      title: 'Timestep', 
                      zeroline: false,
                      showline: false
                    }, 
                    yaxis: {
                      title: 'Prem/Disc (%)',
                      zeroline: false,
                      showline: false
                    },
                    legend: {
                      orientation: "h", 
                      y: 1.1,
                    },
                  } 
                }
              />
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </div> 
  );
};

export default GraphModal;
