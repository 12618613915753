const asNumber = (input, negative) => {
    if(["", "0"].includes(input)) return "0"
    var output = [",","."].includes(input) ? "0.0":input.replace(/[,\\.]/, "|").replace(/[,\\.]/g, '').replace("|", ".")
    if(negative){
        output = output.match(output.includes(".") ? /\-?([0-9]*)*\.[0-9]{0,3}/:/\-([0-9]+)*/)[0]
    } else {
        output = output.match(output.includes(".") ? /([0-9]*)\.[0-9]{0,3}/:/([1-9][0-9]*)/)[0]
    }
    return output
}

const mean = (arr) => {
    const sum = arr.reduce((acc, val) => acc + val, 0);
    return sum / arr.length;
}

const std = (arr) => {
    const avg = mean(arr);
    const squaredDiffs = arr.map(num => Math.pow(num - avg, 2));
    const avgSquaredDiff = squaredDiffs.reduce((acc, val) => acc + val, 0) / arr.length;
    return Math.sqrt(avgSquaredDiff);
}

const cap = str => str.toUpperCase()[0]+str.slice(1)

// Function that recursively find the value of an object path. It only works for objects and path arrays
const deepPath = (obj, path) => path.length > 1 ? deepPath(obj?.[path[0]], path.slice(1)):obj?.[path[0]]

const deepUpdate = (obj, path, newValue) => {return {...obj, [path[0]]: path.length > 1 ? deepUpdate(obj?.[path[0]], path.slice(1), newValue):newValue}}

const shortTimeFormat = dateObject => `${dateObject.getDate()}/${dateObject.getMonth()+1}-${dateObject.toLocaleTimeString()}`

const shortDateFormat = dateObject => `${dateObject.getDate()}/${dateObject.getMonth()+1}-${dateObject.getFullYear()}`

// For using apply inside setTimeout calls when wanting to remove a loading cursor
const removeLoadingClass = node => {
    node.classList.remove("wait")
}

class BoundedArray {
    constructor(maxLength) {
        this.maxLength = maxLength;
        this.array = [];
    }

    push(value) {
        // Add the new value to the array
        this.array.push(value);
        
        // If the array exceeds the max length, remove the oldest item
        if (this.array.length > this.maxLength) {
            this.array.shift();
        }
    }

    getArray() {
        return this.array;
    }
}

const standard_notification = {
    insert: "bottom",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["fadeOut"],
    dismiss: {
      duration: 5000,
      pauseOnHover: true,
      showIcon: true
    }
  }

const default_settings = {
    view: {account_overview: true, active_orders: true, trade_overview: true, dev_tools: false, 
    observation_lists: {
        main: true,
        hedging: false
    }},
    columns:{
        trading:{symbol: true, name: false, last_price: true, bid_size: true, bid_price: true, ask_price: true, ask_size: true,price_sync: true, custom_algorithms: false, time_in_force: false},
        active_orders: {price: true, quantity: true, updated: true, time_in_force: true, custom_algorithms:true, iceberg_shown: true}
    },
    orders: {
        hide_agg: false
    },
    text_size: 10
}

const tricap_token_key = "_tricap_token_"

const switch_default = {uncheckedIcon:false, checkedIcon: false, height: 20, width: 42}

export {asNumber, cap, deepPath,deepUpdate, shortTimeFormat, removeLoadingClass, shortDateFormat, mean, std,
    standard_notification, default_settings, tricap_token_key, switch_default, BoundedArray};