import React, { useContext, useEffect, useState, useRef } from 'react';
import '../App.css';
import Context from '../store/context';
import api from '../api/api';
import Plot from 'react-plotly.js';
import { styles } from '../global/styles';
import { Select, Input } from 'antd';
import LoadingSpinner from "../components/loading-spinner";
import Sidebar from '../components/sidebar';
import { useAuthState } from '../store/auth';

const { Option } = Select;

const cols = {
    "zscore": "Z Score",
    "relative_zscore": "Relative Z Score",
    // "cef": "Fund",
    "time": "Time",
    "bid_prem_disc": "Bid Premium/Discount",
    "mid_prem_disc": "Mid Premium/Discount",
    "ask_prem_disc": "Ask Premium/Discount",
    "hedging_error": "Hedging Error",
}

const xDaysAgo = daysAgo => {
    if(typeof(daysAgo) !== "number" && Number.isNaN(parseInt(daysAgo))) return (new Date()).toISOString().split("T")[0]
    if(!Number.isNaN(parseInt(daysAgo))) daysAgo = parseInt(daysAgo)
    var unix = (new Date()).setDate((new Date()).getDate()-daysAgo)
    return (new Date(unix)).toISOString().split("T")[0]
}

const customSort = (arr) => arr.sort((a, b) => a - b);
const minmax = (arr) => [arr[0], arr[arr.length - 1]]
const isPct = column_name => column_name.includes("disc") || column_name === "hedging_error"

const Graphs = () => {
    const { user, setUser, globalState} = useContext(Context)
    const [apiVersion, setApiVersion] = useState({v2: false, text: ""})
    const MarketdataSocket = useRef(null);
    const isMarketdataReconnecting = useRef(false);
    const isMarketdataWebSocketConnected = useRef(false);
    const [tradingLevels, setTradingLevels] = useState({trading_levels: {}})
    const [intradayHistData, setIntradayHistData] = useState({});
    const [mergeData, setMergeData] = useState(false);
    const [intradayDataRetrieved, setIntradayDataRetrieved] = useState(false);
    const [intradayDataHistRetrieved, setIntradayDataHistRetrieved] = useState(false);
    const [intraDaySelectedSector, setIntraDaySelectedSector] = useState({selected_sector: "Choose Sector"})
    const [interDaySelectedSector, setInterDaySelectedSector] = useState({selected_sector: "Choose Sector"})
    const [includeTradingLevels, setIncludeTradingLevels] = useState({include_trading_levels: true})
    const [selectedVisualization, setSelectedVisualization] = useState({text: "Intraday"})
    const [funds, setFunds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showForecasts, setShowForecasts] = useState(true);
    const [pairTradeFund1, setPairTradeFund1] = useState("ATT");
    const [pairTradeFund2, setPairTradeFund2] = useState("PCT");
    const [premDiscTypeFund1, setPremDiscTypeFund1] = useState("bid");
    const [premDiscTypeFund2, setPremDiscTypeFund2] = useState("bid");
    const settingsRef = useRef(user?.settings);
    const isComponentMounted = useRef(true);
    const [dynamicExtremityValueInput, setDynamicExtremityValue] = useState(0.0);
    const [IntradayPlotSettings, setIntradayPlotSettings] = useState({
        x: "time", y: "zscore", selected_fund: ["SMT"], thickness: 0.5,
        sector: user?.settings?.preferences?.plot_favorite_sector,
        from: xDaysAgo(user?.settings?.preferences?.plot_from_days_ago ?? 7)
    });
    const IntradayPlotSettingsRef = useRef(IntradayPlotSettings);
    const [InterdayPlotSettings, setInterdayPlotSettings] = useState({
        x: "date", y: "prem_disc", selected_fund: ["SMT"], thickness: 0.5,
        sector: user?.settings?.preferences?.plot_favorite_sector,
        from: xDaysAgo(5*365 ?? 5*365)
    });
    const [pairTradePlotSettings, setPairTradePlotSettings] = useState({
        x: "date", y: "prem_disc", thickness: 0.5,
        from: xDaysAgo(7), to: new Date().toISOString().split("T")[0]
    });
    const [premDiscForecasts, setPremDiscForecasts] = useState({});
    const [allInterdayYData, setAllInterdayYData] = useState([]);

    const {
        apiKey,
        setApiKey
      } = useAuthState();

    const calculateMean = (array) => {
        let sum = 0;
        for (let i = 0; i < array.length; i++) {
          sum += array[i];
        }
        return sum / array.length;
    }

    const calculateExtremeValue = (array, newestValue) => {
        let num = 0

        if (newestValue > 0) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] > newestValue) {
                    num += 1
                }
            }
        } else if (newestValue < 0) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] < newestValue) {
                    num += 1
                }
            }
        }

        return num / array.length
    }

    // Function to merge two intraday json objects. Used for merging live and historic intraday data.
    const mergeIntradayObjects = (obj1, obj2) => {
        // Check if either obj1 or obj2 is null, and return the non-null object
        if (Object.keys(obj1)?.length === 0) {
            return obj2;
        }

        for (let key in obj2) {
            for (let key2 in obj2[key]) {
                try {
                    if (typeof obj2[key][key2] === 'string') {
                        obj1[key][key2] = obj2[key][key2];
                    } else {
                        obj1[key][key2] = obj1[key][key2].concat(obj2[key][key2]);
                    }
                } catch (error) {
                    try {
                        obj1[key][key2] = obj2[key][key2];
                    } catch (error) {
                        
                    }
                }
            }
        }
        return obj1;
    }

    const getHistData = async (data) => {
        let histData;
        if (data?.funds) {
            histData = await api(`${apiVersion?.text}fund-data/hist-z-scores?from=${IntradayPlotSettings?.from}`, "POST", {funds: data?.funds });
        } else if (data?.sector) {
            histData = await api(`${apiVersion?.text}fund-data/hist-z-scores?from=${IntradayPlotSettings?.from}`, "POST", {funds: IntradayPlotSettings?.sectors?.[data?.sector]});
        } else if (data?.qs) {
            histData = await api(`${apiVersion?.text}fund-data/hist-z-scores${data?.qs}`, "POST", {funds: IntradayPlotSettings?.selected_fund});
        }
        const histJson = await histData.json();

        return histJson;
    }

    // Function for getting all intraday data.
    const fetchHistData = async (data) => {
        try {
            let histJson;
            if (data?.funds) {
                // intradayJson = await getIntradayData(funds=funds)
                histJson = await getHistData({funds: data?.funds})
            } else if (data?.sector) {
                histJson = await getHistData({sector: data?.sector})
            } else if (data?.qs) {
                histJson = await getHistData({qs: data?.qs})
            }
            
            return histJson;
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors here
            setIsLoading(false);

            return {}
        }
    };

    // Function to establish the WebSocket connection.
    const connectPremDiscWebSocket = () => {  
        const connectWebSocket = () => {
            let heartbeatInterval;
            const newSocket = new WebSocket('wss://trading.tricap.dk/api/ws/marketdata');
    
            newSocket.onopen = () => {
                isMarketdataWebSocketConnected.current = true;
                newSocket.send(JSON.stringify({
                    authorization: apiKey
                }));
                console.log('Connected to messaging WebSocket');
        
                // Send a heartbeat message at regular intervals
                heartbeatInterval = setInterval(() => {
                if (isMarketdataWebSocketConnected.current) {
                    newSocket.send("heartbeat");
                } else {
                    clearInterval(heartbeatInterval);
                }
                }, 3000);

                // Subscribe to relevant message channels.
                const subscriptionData = {
                    "action": "subscribe",
                    "topics": ["premDiscRtd"]
                }
                newSocket.send(JSON.stringify(subscriptionData));
        
                // Save marketdata connection variable.
                MarketdataSocket.current = newSocket;

                isMarketdataReconnecting.current = false;
            };
        
            newSocket.onmessage = (event) => {
                const parsedMsg = JSON.parse(event.data);
                let receivedMsg;
                if (typeof parsedMsg === 'string') {
                    receivedMsg = JSON.parse(parsedMsg);
                } else {
                    receivedMsg = parsedMsg
                }

                // Add most recent timestep to intraday data.
                let updatedIntradayPlotSettings = {...IntradayPlotSettingsRef.current}
                for (let ticker in receivedMsg?.data) {
                    if (ticker in (updatedIntradayPlotSettings?.data || {})) {
                        updatedIntradayPlotSettings['data'][ticker]['time'].push(receivedMsg?.timestamp);
                        updatedIntradayPlotSettings['data'][ticker]['hedging_error'].push(receivedMsg?.data?.[ticker]?.[0]);
                        updatedIntradayPlotSettings['data'][ticker]['bid_prem_disc'].push(receivedMsg?.data?.[ticker]?.[1]);
                        updatedIntradayPlotSettings['data'][ticker]['mid_prem_disc'].push(receivedMsg?.data?.[ticker]?.[2]);
                        updatedIntradayPlotSettings['data'][ticker]['ask_prem_disc'].push(receivedMsg?.data?.[ticker]?.[3]);
                    }
                }
                setIntradayPlotSettings(updatedIntradayPlotSettings);
            };
        
            newSocket.onclose = () => {
                console.log('Websocket connection closed');
                isMarketdataWebSocketConnected.current = false;
                clearInterval(heartbeatInterval);

                // Check if reconnection is already in progress
                if (!isMarketdataReconnecting.current) {
                    if (!settingsRef.current?.disable_streaming_data?.disable_streaming_data) {
                        // Attempt to reconnect every 5 seconds
                        setTimeout(() => {
                            if (isComponentMounted.current) {
                                isMarketdataReconnecting.current = true; // Set the flag

                                // Reconnect to websocket.
                                connectWebSocket();
                            }
                        }, 5000);
                    }
                }
            };
        
            return newSocket;
        };
    
        return connectWebSocket();
    };

    useEffect(() => {
        IntradayPlotSettingsRef.current = IntradayPlotSettings;
    }, [IntradayPlotSettings]);

    useEffect(() => {
        settingsRef.current = user?.settings;
    }, [user])

    // Initiate marketdata and orders websocket connection.
    useEffect(() => {
        if (user?.settings?.disable_streaming_data?.disable_streaming_data && isMarketdataWebSocketConnected?.current) {
            MarketdataSocket.current.close();
        } else if (!user?.settings?.disable_streaming_data?.disable_streaming_data && !isMarketdataWebSocketConnected?.current) {
            MarketdataSocket.current = connectPremDiscWebSocket();
        }

        return () => {
            isComponentMounted.current = false;
            if (MarketdataSocket.current) {
                MarketdataSocket.current.close();
            }
        };
    }, [user]);

    // Merge intraday live data and intraday hist data.
    useEffect(() => {
        if (mergeData && intradayDataRetrieved && intradayDataHistRetrieved) {
            const merged = mergeIntradayObjects({...intradayHistData}, {...IntradayPlotSettings?.data});
            setIntradayPlotSettings({...IntradayPlotSettings, data: {...merged, time: merged?.time?.map(x => new Date(x))}})
            setMergeData(false);
            setIntradayDataRetrieved(false);
            setIntradayDataHistRetrieved(false);
            setIsLoading(false);
        }
    }, [intradayHistData, IntradayPlotSettings?.data, mergeData, intradayDataRetrieved, intradayDataHistRetrieved])

    // Update interday plot y-axis range.
    useEffect(() => {
        if (InterdayPlotSettings?.data && InterdayPlotSettings?.y) {
            let allYData = []
            const data = Object.values(InterdayPlotSettings?.data)
            for (let i=0; i<data.length; i++) {
                if (data[i]) {
                    allYData = [...allYData, ...data[i]?.[InterdayPlotSettings?.y]]
                }
            }
            setAllInterdayYData(allYData)
        }
    }, [InterdayPlotSettings])

    useEffect(() => {
        async function getFunds() {
            await api("fund-data/all-funds").then(res => res.json()).then(data => {
                setFunds(data?.data);
            });
        }
        getFunds()
    }, [])

    useEffect(() => {
        setIsLoading(true);
        async function getIntraday() {
            await api(`${apiVersion?.text}fund-data/z-scores?from=${IntradayPlotSettings?.from}`+ (IntradayPlotSettings?.sector ? `&sector=${IntradayPlotSettings?.sector}`:"")).then(x => x.json()).then(({data, funds, sectors, diff}) => {
                setIntradayPlotSettings({...IntradayPlotSettings, funds, sectors, diff, selected_fund: ["SMT"], data: {...data, time: data?.time?.map(x => new Date(x))}})
                setIsLoading(false);
            })
        }
        getIntraday()
    }, [])

    useEffect(() => {
        async function getInterday() {
            await api(`fund-data/prem-disc?from=${InterdayPlotSettings?.from}`+ (InterdayPlotSettings?.sector ? `&sector=${InterdayPlotSettings?.sector}`:"")).then(x => x.json()).then(({data, funds, sectors, diff}) => setInterdayPlotSettings({...InterdayPlotSettings, funds, sectors, diff, selected_fund: ["SMT"], data: {...data, time: data?.date?.map(x => new Date(x))}}))
        }
        getInterday()
    }, [])

    useEffect(() => {
        async function getPremDiscForecasts() {
            await api(`fund-data/prem-disc-forecasts?date=${new Date().toLocaleDateString('en-US')}`).then(x => x.json()).then(data => setPremDiscForecasts(data))
        }
        getPremDiscForecasts()
    }, [])

    useEffect(() => {
        async function getTradingLevels() {
            await api(`fund-data/trading-levels`).then(x => x.json()).then(({data}) => setTradingLevels({trading_levels: data}))
        }
        getTradingLevels()
    }, [])

    useEffect(() => {
        async function getPairTradeData() {
            const body = {
                dates: [pairTradePlotSettings?.from, pairTradePlotSettings?.to],
                funds: [pairTradeFund1, pairTradeFund2],
                prem_disc_types: [premDiscTypeFund1, premDiscTypeFund2]
            }
            await api(`fund-data/pair-trade`, "POST", body).then(x => x.json()).then(({data}) => setPairTradePlotSettings({...pairTradePlotSettings, data: {...data, time: data?.date?.map(x => new Date(x))}}))
        }
        getPairTradeData()
    }, [])

    useEffect(() => {
        if(IntradayPlotSettings?.data && IntradayPlotSettings?.y){
            var date_range = Object.values(IntradayPlotSettings?.data)?.[0]?.time.reduce((res, strDate) => {
                var d = (new Date(strDate)).toDateString()
                return res.includes(d) ? res:[...res, d]
            }, [])
            setIntradayPlotSettings({...IntradayPlotSettings, range: {
                x: date_range,
                y: (Object.keys(IntradayPlotSettings?.data)?.length < 6 | date_range?.length < 30) && minmax(customSort(Object.values(IntradayPlotSettings?.data).reduce((res, fund) => fund?.[IntradayPlotSettings?.y] ? [...res, ...fund?.[IntradayPlotSettings?.y]]:res, [])))
            }})
        }
    }, [IntradayPlotSettings?.data, IntradayPlotSettings?.y])

    useEffect(() => {
        if(InterdayPlotSettings?.data && InterdayPlotSettings?.y){
            var date_range = Object.values(InterdayPlotSettings?.data)?.[0]?.date.reduce((res, strDate) => {
                var d = (new Date(strDate)).toDateString()
                return res.includes(d) ? res:[...res, d]
            }, [])
            setInterdayPlotSettings({...InterdayPlotSettings, range: {
                x: date_range,
                y: (Object.keys(InterdayPlotSettings?.data)?.length < 6 | date_range?.length < 30) && minmax(customSort(Object.values(InterdayPlotSettings?.data).reduce((res, fund) => fund?.[InterdayPlotSettings?.y] ? [...res, ...fund?.[InterdayPlotSettings?.y]]:res, [])))
            }})
        }
    }, [InterdayPlotSettings?.data, InterdayPlotSettings?.y])

    useEffect(() => {
        if(pairTradePlotSettings?.data && pairTradePlotSettings?.y){
            var date_range = pairTradePlotSettings?.data?.[0]?.time?.reduce((res, strDate) => {
                var d = (new Date(strDate)).toDateString()
                return res.includes(d) ? res:[...res, d]
            }, [])
            setPairTradePlotSettings({...pairTradePlotSettings, range: {
                x: date_range
            }})
        }
    }, [pairTradePlotSettings?.data, pairTradePlotSettings?.y])

    // Implement a ping mechanism to keep the connection alive during idle periods
    useEffect(() => {
        const pingInterval = setInterval(() => {
            if (document.visibilityState === 'visible' && !isMarketdataWebSocketConnected.current) {
                connectPremDiscWebSocket();
            }
        }, 30000); // Check every 30 seconds

        return () => clearInterval(pingInterval);
    }, []);

    if(selectedVisualization.text === 'Intraday') {
        return (
        <main style={{height: "100%", boxSizing: "inherit"}}>
            {isLoading && (
            <div style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 9999,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <LoadingSpinner style={{ margin: 0, padding: 0 }}/>
                <button className='hover-update' style={{padding: 15, marginTop: 0, width: "5%"}} onClick={() => {
                setIsLoading(false);
                }}>Cancel</button>
            </div>)}
            <div style={{justifyContent: "center", display: "flex", paddingTop: 10, flex: 10, float: "left"}}>
            {IntradayPlotSettings?.data && <div style={{borderRadius: 25, padding: 10,backgroundColor: "#fff"}}>
                <Plot
                data={[
                ...Object.values(IntradayPlotSettings.data || [])?.map((plot) => {
                    return {
                    x: plot?.[IntradayPlotSettings?.x],
                    y: plot?.[IntradayPlotSettings?.y],
                    type: 'scatter',
                    mode: 'lines',
                    name: `${plot?.cef} (${(isPct(IntradayPlotSettings?.y) ? 100 * plot?.[IntradayPlotSettings?.y]?.at(-1) : plot?.[IntradayPlotSettings?.y]?.at(-1))?.toFixed(2)?.replace(".", ",")} ${isPct(IntradayPlotSettings?.y) ? "%" : ""})`,
                    hoverinfo: "text+skip",
                    text: plot?.[IntradayPlotSettings?.x]?.map((date, i) =>
                        `Fund: ${plot?.cef}<br>Time: ${date}<br>${cols?.[IntradayPlotSettings?.y]}: ${(isPct(IntradayPlotSettings?.y) ? (plot?.[IntradayPlotSettings?.y][i] * 100) : plot?.[IntradayPlotSettings?.y][i])?.toFixed(2)?.replace(".", ",")} ${isPct(IntradayPlotSettings?.y) ? "%" : ""}`
                    ),
                    line: { width: IntradayPlotSettings?.thickness },
                    };
                }),
                ...((IntradayPlotSettings?.y === "bid_prem_disc" && showForecasts)
                    ? IntradayPlotSettings?.selected_fund?.map((fund) => {
                        if (fund in premDiscForecasts?.data) {
                            if (Math.min(...premDiscForecasts?.data?.[fund]?.prem_disc) < IntradayPlotSettings?.range?.y[0]) {
                                const updatedSettings = { ...IntradayPlotSettings };
                                updatedSettings.range.y[0] = Math.min(...premDiscForecasts?.data?.[fund]?.prem_disc); 
                                setIntradayPlotSettings(updatedSettings);
                            }
                            if (Math.max(...premDiscForecasts?.data?.[fund]?.prem_disc) > IntradayPlotSettings?.range?.y[1]) {
                                const updatedSettings = { ...IntradayPlotSettings };
                                updatedSettings.range.y[1] = Math.max(...premDiscForecasts?.data?.[fund]?.prem_disc); 
                                setIntradayPlotSettings(updatedSettings);
                            }
                            return {
                                x: [
                                IntradayPlotSettings?.data?.[fund]?.time?.[IntradayPlotSettings?.data?.[fund]?.time.length - 1],
                                ...premDiscForecasts?.data?.[fund]?.date?.map(date => date+'T17:30:00')
                                ],
                                y: [
                                IntradayPlotSettings?.data?.[fund]?.bid_prem_disc?.[IntradayPlotSettings?.data?.[fund]?.bid_prem_disc.length - 1],
                                ...premDiscForecasts?.data?.[fund]?.prem_disc,
                                ],
                                type: 'scatter',
                                mode: 'lines',
                                hoverinfo: "text+skip",
                                name: `Prem/Disc Forecast (${fund})`,
                                text: [IntradayPlotSettings?.data?.[fund]?.time?.[IntradayPlotSettings?.data?.[fund]?.time.length - 1],
                                    ...premDiscForecasts?.data?.[fund]?.date?.map(date => date+'T17:30:00')]?.map((date, i) =>
                                `Fund: ${fund}<br>Time: ${date}<br>Prem/Disc Forecast: ${([
                                    IntradayPlotSettings?.data?.[fund]?.bid_prem_disc?.[IntradayPlotSettings?.data?.[fund]?.bid_prem_disc.length - 1],
                                    ...premDiscForecasts?.data?.[fund]?.prem_disc,
                                    ][i] * 100)}%`
                                ),
                                line: { width: IntradayPlotSettings?.thickness },
                            };
                        } else {
                            return {};
                        }
                    })
                    : []),
                ...((IntradayPlotSettings?.y === "mid_prem_disc" && showForecasts)
                    ? IntradayPlotSettings?.selected_fund?.map((fund) => {
                        if (fund in premDiscForecasts?.data) {
                            if (Math.min(...premDiscForecasts?.data?.[fund]?.prem_disc) < IntradayPlotSettings?.range?.y[0]) {
                                const updatedSettings = { ...IntradayPlotSettings };
                                updatedSettings.range.y[0] = Math.min(...premDiscForecasts?.data?.[fund]?.prem_disc); 
                                setIntradayPlotSettings(updatedSettings);
                            }
                            if (Math.max(...premDiscForecasts?.data?.[fund]?.prem_disc) > IntradayPlotSettings?.range?.y[1]) {
                                const updatedSettings = { ...IntradayPlotSettings };
                                updatedSettings.range.y[1] = Math.max(...premDiscForecasts?.data?.[fund]?.prem_disc); 
                                setIntradayPlotSettings(updatedSettings);
                            }
                            return {
                                x: [
                                IntradayPlotSettings?.data?.[fund]?.time?.[IntradayPlotSettings?.data?.[fund]?.time.length - 1],
                                ...premDiscForecasts?.data?.[fund]?.date?.map(date => date+'T17:30:00')
                                ],
                                y: [
                                IntradayPlotSettings?.data?.[fund]?.mid_prem_disc?.[IntradayPlotSettings?.data?.[fund]?.bid_prem_disc.length - 1],
                                ...premDiscForecasts?.data?.[fund]?.prem_disc,
                                ],
                                type: 'scatter',
                                mode: 'lines',
                                hoverinfo: "text+skip",
                                name: `Prem/Disc Forecast (${fund})`,
                                text: [IntradayPlotSettings?.data?.[fund]?.time?.[IntradayPlotSettings?.data?.[fund]?.time.length - 1],
                                    ...premDiscForecasts?.data?.[fund]?.date?.map(date => date+'T17:30:00')]?.map((date, i) =>
                                `Fund: ${fund}<br>Time: ${date}<br>Prem/Disc Forecast: ${([
                                    IntradayPlotSettings?.data?.[fund]?.mid_prem_disc?.[IntradayPlotSettings?.data?.[fund]?.bid_prem_disc.length - 1],
                                    ...premDiscForecasts?.data?.[fund]?.prem_disc,
                                    ][i] * 100)}%`
                                ),
                                line: { width: IntradayPlotSettings?.thickness },
                            };
                        } else {
                            return {};
                        }
                    })
                    : []),
                ...((IntradayPlotSettings?.y === "ask_prem_disc" && showForecasts)
                    ? IntradayPlotSettings?.selected_fund?.map((fund) => {
                        if (fund in premDiscForecasts?.data) {
                            if (Math.min(...premDiscForecasts?.data?.[fund]?.prem_disc) < IntradayPlotSettings?.range?.y[0]) {
                                const updatedSettings = { ...IntradayPlotSettings };
                                updatedSettings.range.y[0] = Math.min(...premDiscForecasts?.data?.[fund]?.prem_disc); 
                                setIntradayPlotSettings(updatedSettings);
                            }
                            if (Math.max(...premDiscForecasts?.data?.[fund]?.prem_disc) > IntradayPlotSettings?.range?.y[1]) {
                                const updatedSettings = { ...IntradayPlotSettings };
                                updatedSettings.range.y[1] = Math.max(...premDiscForecasts?.data?.[fund]?.prem_disc); 
                                setIntradayPlotSettings(updatedSettings);
                            }
                            return {
                                x: [
                                IntradayPlotSettings?.data?.[fund]?.time?.[IntradayPlotSettings?.data?.[fund]?.time.length - 1],
                                ...premDiscForecasts?.data?.[fund]?.date?.map(date => date+'T17:30:00')
                                ],
                                y: [
                                IntradayPlotSettings?.data?.[fund]?.ask_prem_disc?.[IntradayPlotSettings?.data?.[fund]?.bid_prem_disc.length - 1],
                                ...premDiscForecasts?.data?.[fund]?.prem_disc,
                                ],
                                type: 'scatter',
                                mode: 'lines',
                                hoverinfo: "text+skip",
                                name: `Prem/Disc Forecast (${fund})`,
                                text: [IntradayPlotSettings?.data?.[fund]?.time?.[IntradayPlotSettings?.data?.[fund]?.time.length - 1],
                                    ...premDiscForecasts?.data?.[fund]?.date?.map(date => date+'T17:30:00')]?.map((date, i) =>
                                `Fund: ${fund}<br>Time: ${date}<br>Prem/Disc Forecast: ${([
                                    IntradayPlotSettings?.data?.[fund]?.ask_prem_disc?.[IntradayPlotSettings?.data?.[fund]?.bid_prem_disc.length - 1],
                                    ...premDiscForecasts?.data?.[fund]?.prem_disc,
                                    ][i] * 100)}%`
                                ),
                                line: { width: IntradayPlotSettings?.thickness },
                            };
                        } else {
                            return {};
                        }
                    })
                    : []),
            {
                x:[IntradayPlotSettings?.range?.x?.at(-1)],
                // y:[IntradayPlotSettings?.range?.y?.at(-1)],
                name: `Last updated: ${new Date(Object.values(IntradayPlotSettings?.data)?.[0]?.time?.at(-1))?.toLocaleTimeString()}`,
                hoverinfo: "skip",
                type:"scatter",
                mode: "markers",
                marker: {color: 
                    // If more than 1 hour old the color turns red
                    (30*60*1000) > (new Date() - new Date(Object.values(IntradayPlotSettings?.data)?.[0]?.time?.at(-1))) ?
                    "green":"red"}
            }]
            }
                layout={ {width: 1520, height: 1000, 
                xaxis: {title: cols[IntradayPlotSettings?.x],
                rangebreaks: [
                IntradayPlotSettings?.diff > 30 || {
                    bounds: [17.6,9.0166], pattern: "hour"
                },
                {
                    bounds: ["sat", "mon"], pattern: "day of week"
                },
                {
                    values: [new Date("2022-08-26T10:54:48"), new Date("2022-08-26T15:23:46")], dvalue: 1000,
                    bounds: [new Date("2022-08-26T10:54:48"), new Date("2022-08-26T15:23:46")]
                }]
                }, 
                yaxis: {title: cols[IntradayPlotSettings?.y], range: IntradayPlotSettings?.range?.y, 
                    dtick: isPct(IntradayPlotSettings?.y)&(IntradayPlotSettings?.range?.y[1] - IntradayPlotSettings?.range?.y[0] > 0.1) ? 0.01:"",
                    tickformat: isPct(IntradayPlotSettings?.y) && "p"},
                legend: {
                    orientation: "h", 
                    y: 1.1,
                },
                // shapes: IntradayPlotSettings?.range?.x?.map(dateline => {
                //     var datesplit = new Date(`${dateline} 09:00:01`)
                //     return {
                //         type:"line",
                //         x0: datesplit,
                //         x1: datesplit,
                //         y0: IntradayPlotSettings?.range?.y[0],
                //         y1: IntradayPlotSettings?.range?.y[1],
                //         line: {
                //             width: IntradayPlotSettings?.thickness,
                //         }
                //     }
                // })
            } }
            /></div>}
            </div>
            <Sidebar>
                <div style={{justifyContent: "space-around", margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:10, paddingBottom:20}}>
                <div style={{justifyContent: "space-around", margin: 10, display: "flex", flex: 2, flexDirection: "column"}}>
                    <h3 style={{alignSelf: "flex-end"}}>Visualization type:</h3>
                    <select value={selectedVisualization?.text}
                    style={{width: 180, padding: 5, alignSelf: "flex-end", color: "black"}}
                    onChange={e => {
                        e.persist()
                        setSelectedVisualization({text: e?.target?.value})
                        }}>
                        <option>Intraday</option>
                        <option>Interday</option>
                        <option>Pair Trade</option>
                    </select>
                    <p style={{alignSelf: "flex-end"}}>Funds:</p>
                    <select value={IntradayPlotSettings?.selected_fund?.length == 0 ? ["SMT"]:IntradayPlotSettings?.selected_fund} multiple
                    style={{width: 180, height: 400, padding: 5, alignSelf: "flex-end", color: "black"}}
                    onChange={e => {
                        setIsLoading(true);
                        e.persist();
                        var funds = []
                        for(let i = 0; i < e.target.options.length; i++) if(e.target.options[i].selected) funds.push(e.target.options[i].value)
                        if(funds.length > 0) setIntradayPlotSettings({...IntradayPlotSettings, selected_fund:funds})
                        setMergeData(true);
                        api(`${apiVersion?.text}fund-data/z-scores?from=${IntradayPlotSettings?.from}`, "POST", {funds}).then(x => x.json()).then(({data, diff}) => { 
                            setIntradayPlotSettings({...IntradayPlotSettings, diff, selected_fund: funds, data: {...data, time: data?.time?.map(x => new Date(x))}})
                            setIntradayDataRetrieved(true);
                            if (Math.round((new Date()-new Date(IntradayPlotSettings?.from)) / (1000*60*60*24)) <= 90) {
                                setIsLoading(false);
                            }
                            })
                        if (Math.round((new Date()-new Date(IntradayPlotSettings?.from)) / (1000*60*60*24)) > 90) {
                            fetchHistData({funds: funds}).then(histData => {
                                setIntradayHistData(histData?.data);
                                setIntradayDataHistRetrieved(true);
                            });
                        }
                        }}>
                        {IntradayPlotSettings?.funds && IntradayPlotSettings?.funds?.map(fund => (<option selected>{fund}</option>))}
                    </select>
                    <p style={{alignSelf: "flex-end"}}>Sector:</p>
                    <select value={intraDaySelectedSector?.selected_sector} 
                    style={{width: 180, padding: 5, alignSelf: "flex-end", color: "black"}}
                    onChange={e => {
                        setIsLoading(true);
                        e.persist();
                        setMergeData(true);
                        api(`${apiVersion?.text}fund-data/z-scores?from=${IntradayPlotSettings?.from}`, "POST", {funds: IntradayPlotSettings?.sectors?.[e?.target?.value]}).then(x => x.json()).then(({data, diff}) => {
                            setIntradayPlotSettings({...IntradayPlotSettings, diff, selected_fund: IntradayPlotSettings?.sectors?.[e?.target?.value], data: {...data, time: data?.time?.map(x => new Date(x))}})
                            setIntradayDataRetrieved(true)
                            if (Math.round((new Date()-new Date(IntradayPlotSettings?.from)) / (1000*60*60*24)) <= 90) {
                                setIsLoading(false);
                            }
                        })
                        setIntraDaySelectedSector({selected_sector: e?.target?.value})
                        if (Math.round((new Date()-new Date(IntradayPlotSettings?.from)) / (1000*60*60*24)) > 90) {
                            fetchHistData({sector: e?.target?.value}).then(histData => {
                                setIntradayHistData(histData?.data);
                                setIntradayDataHistRetrieved(true)
                            });
                        }
                        }}>
                        {IntradayPlotSettings?.sectors && Object.keys(IntradayPlotSettings?.sectors)?.map(sector => (<option>{sector}</option>))}
                    </select>
                    <p style={{alignSelf: "flex-end"}}>X axis:</p>
                    <select style={{alignSelf: "flex-end", width: 180, padding: 5, color: "black"}} value={IntradayPlotSettings?.x} onChange={e => {
                        setIntradayPlotSettings({...IntradayPlotSettings, x: e?.target?.value})
                        }}>
                        {Object.entries(cols).map(([value, name]) => IntradayPlotSettings?.y === value || (
                            <option value={value}>{name}</option>
                        ))}
                    </select>
                    <p style={{alignSelf: "flex-end"}}>Y axis:</p>
                    <select style={{alignSelf: "flex-end", width: 180, padding: 5, color: "black"}} value={IntradayPlotSettings?.y} onChange={e => {
                        setIntradayPlotSettings({...IntradayPlotSettings, y: e?.target?.value})
                        }}>
                        {Object.entries(cols).map(([value, name]) => IntradayPlotSettings?.x === value || (
                            <option value={value}>{name}</option>
                        ))}
                    </select>
                    <p style={{alignSelf: "flex-end"}}>From:</p>
                    <input value={IntradayPlotSettings?.from} style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200, alignSelf: "flex-end"}} type="date" onChange={e => setIntradayPlotSettings({...IntradayPlotSettings, from: e?.target?.value})}/>
                    <p style={{alignSelf: "flex-end"}}>To:</p>
                    <input style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200, alignSelf: "flex-end"}} type="date" onChange={e => setIntradayPlotSettings({...IntradayPlotSettings, to: e?.target?.value})}/>
                    <button style={{alignSelf: "flex-end"}} className='hover-update' onClick={() => {
                    setIsLoading(true);
                    setMergeData(true);
                    var qs_parts = []
                    for(let date_limiter of ["from", "to"]) if(IntradayPlotSettings?.[date_limiter]) qs_parts.push(`${date_limiter}=${IntradayPlotSettings?.[date_limiter]}`)
                    var qs = qs_parts.length==0? "":`?${qs_parts.join("&")}`
                    api(`${apiVersion?.text}fund-data/z-scores${qs}`, "POST", {funds: IntradayPlotSettings?.selected_fund}).then(x => x.json()).then(({data, diff}) => {
                        setIntradayPlotSettings({...IntradayPlotSettings, diff, data: {...data, time: data?.time?.map(x => new Date(x))}})
                        setIntradayDataRetrieved(true)
                        if (Math.round((new Date()-new Date(IntradayPlotSettings?.from)) / (1000*60*60*24)) <= 90) {
                            setIsLoading(false);
                        }
                    })
                    if (Math.round((new Date()-new Date(IntradayPlotSettings?.from)) / (1000*60*60*24)) > 90) {
                        fetchHistData({qs: qs}).then(histData => {
                            setIntradayHistData(histData?.data);
                            setIntradayDataHistRetrieved(true)
                        });
                    }
                }}>Update time</button>
                <label
                    style={{ border: 0, padding: 0, verticalAlign: "middle", alignSelf: "flex-end", marginTop: 20}}
                    htmlFor="radio-button-show-forecasts">
                    Show Prem/Disc Forecasts:
                </label>
                <div style={{ display: "flex", alignItems: "center", alignSelf: "flex-end" }}>
                    <Input
                        style={{ border: 0, margin: 0, padding: 0, verticalAlign: "middle", width: 20, height: 20}}
                        id="radio-button-show-forecasts"
                        type="radio"
                        checked={showForecasts}
                        onClick={(e) => {
                            setShowForecasts(!showForecasts);
                        }}
                    />
                </div>
                <p style={{alignSelf: "flex-end", marginTop: 20}}>Line thickness:</p>
                <Input style={{maxWidth:100, alignSelf: "flex-end"}}
                    type="number"
                    step="0.1"
                    value={IntradayPlotSettings?.thickness || 0.0}
                    onChange={(e) => {
                        setIntradayPlotSettings({...IntradayPlotSettings, thickness: e?.target?.value})
                    }}
                />
                </div>
                </div>
            </Sidebar>
            {/* <p>{IntradayPlotSettings?.data && JSON.stringify(Object.values(IntradayPlotSettings?.data)?.[0]?.time.reduce((res, strDate) => {
                var d = (new Date(strDate)).toDateString()
                return res.includes(d) ? res:[...res, d]
            }, []))}</p> */}
            {/* <p>{JSON.stringify(Object.values(IntradayPlotSettings?.data ?? {})?.[0]?.time?.slice(1120,1440))}</p> */}
            {/* <p style={{backgroundColor: "#ddd"}}>{JSON.stringify(IntradayPlotSettings)}</p> */}
        </main>
        )} else if(selectedVisualization.text === 'Interday') {
            return (
                <main style={{height: "100%", boxSizing: "inherit"}}>
                {isLoading && (
                <div style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 9999,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <LoadingSpinner style={{ margin: 0, padding: 0 }}/>
                    <button className='hover-update' style={{padding: 15, marginTop: 0, width: "5%"}} onClick={() => {
                    setIsLoading(false);
                    }}>Cancel</button>
                </div>)}
                <div style={{justifyContent: "center", display: "flex", paddingTop: 10, flex: 10, float: 'left'}}>
                {InterdayPlotSettings?.data && <div style={{borderRadius: 25, padding: 10,backgroundColor: "#fff"}}>
                <Plot
                data={
                [...Object.values(InterdayPlotSettings?.data)?.map(plot => {return {
                    x: plot?.[InterdayPlotSettings?.x],
                    y: plot?.[InterdayPlotSettings?.y],
                    type: 'scatter',
                    mode: 'lines',
                    name: `${plot?.ticker} (${(isPct(InterdayPlotSettings?.y) ? 100*plot?.[InterdayPlotSettings?.y]?.at(-1):plot?.[InterdayPlotSettings?.y]?.at(-1))?.toFixed(2)?.replace(".", ",")} ${isPct(InterdayPlotSettings?.y) ? "%":""})`,
                    hoverinfo: "text+skip",
                    text: plot?.[InterdayPlotSettings?.x]?.map((date, i) => 
                    `Fund: ${plot?.ticker}<br>Time: ${date}<br>Premium/Discount: ${(isPct(InterdayPlotSettings?.y) ? (plot?.[InterdayPlotSettings?.y][i]*100):plot?.[InterdayPlotSettings?.y][i])?.toFixed(2)?.replace(".", ",")} ${isPct(InterdayPlotSettings?.y) ? "%":""}`),
                    line: {width: InterdayPlotSettings?.thickness}
                }}),
                ...Object.values(tradingLevels?.trading_levels)?.reduce((res, fund) => {
                    if(includeTradingLevels?.include_trading_levels === true) {
                        if(InterdayPlotSettings?.selected_fund.includes(fund?.ticker)) {
                            res.push({
                                x: InterdayPlotSettings?.data?.[fund?.ticker]?.date,
                                y: new Array(InterdayPlotSettings?.data?.[fund?.ticker]?.date?.length).fill(fund?.full_utilization_long),
                                type: 'scatter',
                                mode: 'lines',
                                name: `Full utilization (long): ${fund?.ticker}`,
                                hoverinfo: "text+skip",
                                marker: {color: '#013220'},
                                line: {width: InterdayPlotSettings?.thickness}
                            })
                        }
                    }
                    return res
                    }, []),
                ...Object.values(tradingLevels?.trading_levels)?.reduce((res, fund) => {
                    if(includeTradingLevels?.include_trading_levels === true) {
                        if(InterdayPlotSettings?.selected_fund.includes(fund?.ticker)) {
                            res.push({
                                x: InterdayPlotSettings?.data?.[fund?.ticker]?.date,
                                y: new Array(InterdayPlotSettings?.data?.[fund?.ticker]?.date?.length).fill(fund?.full_utilization_short),
                                type: 'scatter',
                                mode: 'lines',
                                name: `Full utilization (short): ${fund?.ticker}`,
                                hoverinfo: "text+skip",
                                marker: {color: 'red'},
                                line: {width: InterdayPlotSettings?.thickness}
                            })
                        }
                    }
                    return res
                    }, []),
                ...Object.values(tradingLevels?.trading_levels)?.reduce((res, fund) => {
                    if(includeTradingLevels?.include_trading_levels === true) {
                        if(InterdayPlotSettings?.selected_fund.includes(fund?.ticker)) {
                            res.push({
                                x: InterdayPlotSettings?.data?.[fund?.ticker]?.date,
                                y: new Array(InterdayPlotSettings?.data?.[fund?.ticker]?.date?.length).fill(fund?.initiate_long),
                                type: 'scatter',
                                mode: 'lines',
                                name: `Initiate (long): ${fund?.ticker}`,
                                hoverinfo: "text+skip",
                                marker: {color: '#90EE90'},
                                line: {width: InterdayPlotSettings?.thickness}
                            })
                        }
                    }
                    return res
                    }, []),
                ...Object.values(tradingLevels?.trading_levels)?.reduce((res, fund) => {
                    if(includeTradingLevels?.include_trading_levels === true) {
                        if(InterdayPlotSettings?.selected_fund.includes(fund?.ticker)) {
                            res.push({
                                x: InterdayPlotSettings?.data?.[fund?.ticker]?.date,
                                y: new Array(InterdayPlotSettings?.data?.[fund?.ticker]?.date?.length).fill(fund?.initiate_short),
                                type: 'scatter',
                                mode: 'lines',
                                name: `Initiate (short): ${fund?.ticker}`,
                                hoverinfo: "text+skip",
                                marker: {color: 'orange'},
                                line: {width: InterdayPlotSettings?.thickness}
                            })
                        }
                    }
                    return res
                    }, []),
                    ...((InterdayPlotSettings?.y === "prem_disc" && showForecasts)
                    ? InterdayPlotSettings?.selected_fund?.map((fund) => {
                        if (fund in premDiscForecasts?.data) {
                            if (Math.min(...premDiscForecasts?.data?.[fund]?.prem_disc) < InterdayPlotSettings?.range?.y[0]) {
                                const updatedSettings = { ...InterdayPlotSettings };
                                updatedSettings.range.y[0] = Math.min(...premDiscForecasts?.data?.[fund]?.prem_disc); 
                                setInterdayPlotSettings(updatedSettings);
                            }
                            if (Math.max(...premDiscForecasts?.data?.[fund]?.prem_disc) > InterdayPlotSettings?.range?.y[1]) {
                                const updatedSettings = { ...InterdayPlotSettings };
                                updatedSettings.range.y[1] = Math.max(...premDiscForecasts?.data?.[fund]?.prem_disc); 
                                setInterdayPlotSettings(updatedSettings);
                            }
                            return {
                                x: [
                                InterdayPlotSettings?.data?.[fund]?.date?.[InterdayPlotSettings?.data?.[fund]?.date.length - 1],
                                ...premDiscForecasts?.data?.[fund]?.date?.map(date => date+'T17:30:00')
                                ],
                                y: [
                                InterdayPlotSettings?.data?.[fund]?.prem_disc?.[InterdayPlotSettings?.data?.[fund]?.prem_disc.length - 1],
                                ...premDiscForecasts?.data?.[fund]?.prem_disc,
                                ],
                                type: 'scatter',
                                mode: 'lines',
                                hoverinfo: "text+skip",
                                name: `Prem/Disc Forecast (${fund})`,
                                text: [InterdayPlotSettings?.data?.[fund]?.date?.[InterdayPlotSettings?.data?.[fund]?.date.length - 1],
                                    ...premDiscForecasts?.data?.[fund]?.date?.map(date => date+'T17:30:00')]?.map((date, i) =>
                                `Fund: ${fund}<br>Time: ${date}<br>Prem/Disc Forecast: ${([
                                    InterdayPlotSettings?.data?.[fund]?.prem_disc?.[InterdayPlotSettings?.data?.[fund]?.prem_disc.length - 1],
                                    ...premDiscForecasts?.data?.[fund]?.prem_disc,
                                    ][i] * 100)}%`
                                ),
                                line: { width: InterdayPlotSettings?.thickness },
                            };
                        } else {
                            return {};
                        }
                    })
                    : []),
                {
                    x:[InterdayPlotSettings?.range?.x?.at(-1)],
                    // y:[InterdayPlotSettings?.range?.y?.at(-1)],
                    name: `Last updated: ${new Date(Object.values(InterdayPlotSettings?.data)?.[0]?.date?.at(-1))}`,
                    hoverinfo: "skip",
                    type:"scatter",
                    mode: "markers"
                }]
                }
                    layout={ {width: 1520, height: 1000, 
                    xaxis: {title: "Time", 
                            zeroline: false,
                            showline: false
                }, 
                    yaxis: {title: "Premium/Discount", range: [Math.min(...allInterdayYData), Math.max(...allInterdayYData)], 
                        dtick: isPct(InterdayPlotSettings?.y)&(InterdayPlotSettings?.range?.y[1] - InterdayPlotSettings?.range?.y[0] > 0.1) ? 0.01:"",
                        tickformat: isPct(InterdayPlotSettings?.y) && "p",
                        zeroline: false,
                        showline: false
                    },
                    legend: {
                        orientation: "h", 
                        y: 1.1,
                    },
                } }
                /></div>}
                </div>
                <Sidebar>
                    <div style={{justifyContent: "space-around", margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:10, paddingBottom:20}}>
                    <div style={{justifyContent: "space-around", margin: 10, display: "flex", flex: 2, flexDirection: "column"}}>
                        <h3 style={{alignSelf: "flex-end"}}>Visualization type:</h3>
                        <select value={selectedVisualization?.text}
                        style={{width: 180, padding: 5, alignSelf: "flex-end", color: "black"}}
                        onChange={e => {
                            e.persist()
                            setSelectedVisualization({text: e?.target?.value})
                            }}>
                            <option>Intraday</option>
                            <option>Interday</option>
                            <option>Pair Trade</option>
                        </select>
                        <p style={{alignSelf: "flex-end"}}>Funds:</p>
                        <select value={InterdayPlotSettings?.selected_fund?.length == 0 ? ["SMT"]:InterdayPlotSettings?.selected_fund} multiple
                        style={{width: 180, height: 400, padding: 5, alignSelf: "flex-end", color: "black"}}
                        onChange={e => {
                            setIsLoading(true);
                            e.persist();
                            var funds = []
                            for(let i = 0; i < e.target.options.length; i++) if(e.target.options[i].selected) funds.push(e.target.options[i].value)
                            if(funds.length > 0) setInterdayPlotSettings({...InterdayPlotSettings, selected_fund:funds})
                            api(`${apiVersion?.text}fund-data/prem-disc?from=${InterdayPlotSettings?.from}`, "POST", {funds}).then(x => x.json()).then(({data, diff}) => {
                                setInterdayPlotSettings({...InterdayPlotSettings, diff, selected_fund: funds, data: {...data, time: data?.date?.map(x => new Date(x))}})
                                setIsLoading(false);
                            })
                            }}>
                            {InterdayPlotSettings?.funds && InterdayPlotSettings?.funds?.map(fund => (<option selected>{fund}</option>))}
                        </select>
                        <p style={{alignSelf: "flex-end"}}>Sector:</p>
                        <select value={interDaySelectedSector?.selected_sector} 
                        style={{width: 180, padding: 5, alignSelf: "flex-end", color: "black"}}
                        onChange={e => {
                            setIsLoading(true);
                            e.persist();
                            api(`${apiVersion?.text}fund-data/prem-disc?from=${InterdayPlotSettings?.from}`, "POST", {funds: InterdayPlotSettings?.sectors?.[e?.target?.value]}).then(x => x.json()).then(({data, diff}) => {
                                setInterdayPlotSettings({...InterdayPlotSettings, diff, selected_fund: InterdayPlotSettings?.sectors?.[e?.target?.value], data: {...data, time: data?.date?.map(x => new Date(x))}})
                                setIsLoading(false);
                            })
                            setInterDaySelectedSector({selected_sector: e?.target?.value})
                            }}>
                            {InterdayPlotSettings?.sectors && Object.keys(InterdayPlotSettings?.sectors)?.map(sector => (<option>{sector}</option>))}
                        </select>
                        <label
                            style={{ border: 0, padding: 0, verticalAlign: "middle", alignSelf: "flex-end", marginTop: 5}}
                            htmlFor="radio-button-trading-levels">
                            Include Trading Levels:
                        </label>
                        <div style={{ display: "flex", alignItems: "center", alignSelf: "flex-end" }}>
                            <Input
                                style={{ border: 0, margin: 0, padding: 0, verticalAlign: "middle", width: 20, height: 20}}
                                id="radio-button-trading-levels"
                                type="radio"
                                checked={includeTradingLevels?.include_trading_levels}
                                onClick={(e) => {
                                    setIncludeTradingLevels({include_trading_levels: !includeTradingLevels?.include_trading_levels})
                                }}
                            />
                        </div>
                        <p style={{alignSelf: "flex-end"}}>From:</p>
                        <input value={InterdayPlotSettings?.from} style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200, alignSelf: "flex-end"}} type="date" onChange={e => setInterdayPlotSettings({...InterdayPlotSettings, from: e?.target?.value})}/>
                        <p style={{alignSelf: "flex-end"}}>To:</p>
                        <input style={{...styles.input, padding: 10, fontSize: 12, maxWidth: 200, alignSelf: "flex-end"}} type="date" onChange={e => setInterdayPlotSettings({...InterdayPlotSettings, to: e?.target?.value})}/>
                        <button style={{alignSelf: "flex-end", }} className='hover-update' onClick={() => {
                        setIsLoading(true);
                        var qs_parts = []
                        for(let date_limiter of ["from", "to"]) if(InterdayPlotSettings?.[date_limiter]) qs_parts.push(`${date_limiter}=${InterdayPlotSettings?.[date_limiter]}`)
                        var qs = qs_parts.length==0? "":`?${qs_parts.join("&")}`
                        api(`fund-data/prem-disc${qs}`, "POST", {funds: InterdayPlotSettings?.selected_fund}).then(x => x.json()).then(({data, diff}) => {
                            setInterdayPlotSettings({...InterdayPlotSettings, diff, data: {...data, time: data?.date?.map(x => new Date(x))}})
                            setIsLoading(false);
                        })
                    }}>Update time</button>
                    <label
                        style={{ border: 0, padding: 0, verticalAlign: "middle", alignSelf: "flex-end", marginTop: 20}}
                        htmlFor="radio-button-show-forecasts">
                        Show Prem/Disc Forecasts:
                    </label>
                    <div style={{ display: "flex", alignItems: "center", alignSelf: "flex-end" }}>
                        <Input
                            style={{ border: 0, margin: 0, padding: 0, verticalAlign: "middle", width: 20, height: 20}}
                            id="radio-button-show-forecasts"
                            type="radio"
                            checked={showForecasts}
                            onClick={(e) => {
                                setShowForecasts(!showForecasts);
                            }}
                        />
                    </div>
                    <p style={{alignSelf: "flex-end", marginTop: 20}}>Line thickness:</p>
                    <Input style={{maxWidth:100, alignSelf: "flex-end"}}
                        type="number"
                        step="0.1"
                        value={InterdayPlotSettings?.thickness || 0.0}
                        onChange={(e) => {
                            setInterdayPlotSettings({...InterdayPlotSettings, thickness: e?.target?.value})
                        }}
                    />
                    </div>
                    </div>
                </Sidebar>
                </main>
            )
        } else if(selectedVisualization.text === 'Pair Trade') {
            return (
                <main style={{height: "100%", boxSizing: "inherit"}}>
                    {isLoading && (
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                    <LoadingSpinner style={{ margin: 0, padding: 0 }}/>
                    <button className='hover-update' style={{padding: 15, marginTop: 0, width: "5%"}} onClick={() => {
                    setIsLoading(false);
                    }}>Cancel</button>
                </div>)}
                <div style={{justifyContent: "center", display: "flex", paddingTop: 10, flex: 10, float: "left"}}>
                {pairTradePlotSettings?.data && <div style={{borderRadius: 25, padding: 10,backgroundColor: "#fff"}}>
                <Plot
                data={
                [{
                    x: pairTradePlotSettings?.data?.[0]?.time,
                    y: pairTradePlotSettings?.data?.[0]?.prem_disc_diff,
                    type: 'scatter',
                    mode: 'lines',
                    name: `${pairTradeFund1}/${pairTradeFund2} (${(isPct(pairTradePlotSettings?.y) ? 100*pairTradePlotSettings?.data?.[0]?.prem_disc_diff?.at(-1):pairTradePlotSettings?.data?.[0]?.prem_disc_diff?.at(-1))?.toFixed(2)?.replace(".", ",")} ${isPct(pairTradePlotSettings?.y) ? "%":""})`,
                    hoverinfo: "text+skip",
                    text: pairTradePlotSettings?.data?.[0]?.time?.map((date, i) => 
                    `Pair Trade: ${pairTradeFund1}/${pairTradeFund2}<br>Time: ${date}<br>Premium/Discount Diff: ${(isPct(pairTradePlotSettings?.y) ? (pairTradePlotSettings?.data?.[0]?.prem_disc_diff[i]*100):pairTradePlotSettings?.data?.[0]?.prem_disc_diff[i])?.toFixed(2)?.replace(".", ",")} ${isPct(pairTradePlotSettings?.y) ? "%":""}`),
                    line: {width: pairTradePlotSettings?.thickness}
                },
                {
                    x: pairTradePlotSettings?.data?.[0]?.time,
                    y: new Array(pairTradePlotSettings?.data?.[0]?.prem_disc_diff?.length).fill(calculateMean(pairTradePlotSettings?.data?.[0]?.prem_disc_diff)),
                    type: 'scatter',
                    mode: 'lines',
                    name: `Mean Prem/Disc: ${pairTradeFund1}/${pairTradeFund2}`,
                    hoverinfo: "text+skip",
                    marker: {color: 'orange'},
                    line: {width: pairTradePlotSettings?.thickness}
                }]
                }
                    layout={ {width: 1400, height: 900, showlegend: true,
                    xaxis: {title: "Time", 
                            zeroline: false,
                            showline: false,
                            rangebreaks: [
                                {
                                    bounds: [17.5,9.0166], pattern: "hour"
                                },
                                {
                                    bounds: ["sat", "mon"], pattern: "day of week"
                                },
                                {
                                    values: [new Date("2022-08-26T10:54:48"), new Date("2022-08-26T15:23:46")], dvalue: 1000,
                                    bounds: [new Date("2022-08-26T10:54:48"), new Date("2022-08-26T15:23:46")]
                                }], 
                    }, 
                    yaxis: {title: "Premium/Discount Diff",
                        tickformat: "p",
                        zeroline: false,
                        showline: false
                    },
                    legend: {
                        orientation: "h", 
                        y: 1.1,
                    },
                } }
                /></div>}
                </div>
                <Sidebar>
                    <div style={{justifyContent: "space-around", margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:10, paddingBottom:20}}>
                    <div style={{justifyContent: "space-around", margin: 10, display: "flex", flex: 2, flexDirection: "column", float: "left"}}>
                        <h3 style={{marginBottom: 5, alignSelf: "flex-end"}}>Visualization type:</h3>
                        <select value={selectedVisualization?.text}
                        style={{width: 180, padding: 5, marginBottom: 20, alignSelf: "flex-end", color: "black"}}
                        onChange={e => {
                            e.persist()
                            setSelectedVisualization({text: e?.target?.value})
                            }}>
                            <option>Intraday</option>
                            <option>Interday</option>
                            <option>Pair Trade</option>
                        </select>
                        <p style={{paddingBottom:5, alignSelf: "flex-end"}}>Fund (1):</p>
                        <Select
                            showSearch
                            style={{ width: 200, paddingBottom:5, alignSelf: "flex-end", color: "black"}}
                            placeholder="Select a fund"
                            optionFilterProp="fund"
                            value={pairTradeFund1}
                            onChange={value => setPairTradeFund1(value)}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {funds.map((fundOption) => (
                                <Option key={fundOption} value={fundOption}>{fundOption}</Option>
                            ))}
                        </Select>
                        <p style={{paddingBottom:5, alignSelf: "flex-end"}}>Prem/Disc:</p>
                        <Select
                            style={{ width: 200, paddingBottom:20, alignSelf: "flex-end", color: "black"}}
                            placeholder="Select Bid/Mid/Ask"
                            optionFilterProp="prem_disc_type"
                            value={premDiscTypeFund1}
                            onChange={value => setPremDiscTypeFund1(value)}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                                <Option key="bid_fund1" value={"bid"}>Bid</Option>
                                <Option key="mid_fund1" value={"mid"}>Mid</Option>
                                <Option key="ask_fund1" value={"ask"}>Ask</Option>
                        </Select>
                        <p style={{paddingBottom:5, alignSelf: "flex-end"}}>Fund (2):</p>
                        <Select
                            showSearch
                            style={{ width: 200, paddingBottom:5, alignSelf: "flex-end", color: "black"}}
                            placeholder="Select a fund"
                            optionFilterProp="fund"
                            value={pairTradeFund2}
                            onChange={value => setPairTradeFund2(value)}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {funds.map((fundOption) => (
                                <Option key={fundOption} value={fundOption}>{fundOption}</Option>
                            ))}
                        </Select>
                        <p style={{paddingBottom:5, alignSelf: "flex-end"}}>Prem/Disc:</p>
                        <Select
                            style={{ width: 200, paddingBottom:20, alignSelf: "flex-end", color: "black"}}
                            placeholder="Select Bid/Mid/Ask"
                            optionFilterProp="prem_disc_type"
                            value={premDiscTypeFund2}
                            onChange={value => setPremDiscTypeFund2(value)}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                                <Option key="bid_fund2" value={"bid"}>Bid</Option>
                                <Option key="mid_fund2" value={"mid"}>Mid</Option>
                                <Option key="ask_fund2" value={"ask"}>Ask</Option>
                        </Select>
                        <p style={{marginBottom: 5, alignSelf: "flex-end"}}>From:</p>
                        <input value={pairTradePlotSettings?.from} style={{...styles.input, padding: 10, marginBottom: 20, fontSize: 12, maxWidth: 200, alignSelf: "flex-end"}} type="date" onChange={e => setPairTradePlotSettings({...pairTradePlotSettings, from: e?.target?.value})}/>
                        <p style={{marginBottom: 5, alignSelf: "flex-end"}}>To:</p>
                        <input value={pairTradePlotSettings?.to} style={{...styles.input, padding: 10, marginBottom: 20, fontSize: 12, maxWidth: 200, alignSelf: "flex-end"}} type="date" onChange={e => setPairTradePlotSettings({...pairTradePlotSettings, to: e?.target?.value})}/>
                        <button className='hover-update' style={{marginBottom: 20, width: 215, alignSelf: "flex-end"}} onClick={() => {
                            setIsLoading(true);
                            const body = {
                                dates: [pairTradePlotSettings?.from, pairTradePlotSettings?.to],
                                funds: [pairTradeFund1, pairTradeFund2],
                                prem_disc_types: [premDiscTypeFund1, premDiscTypeFund2]
                            }
                            api(`fund-data/pair-trade`, "POST", body).then(x => x.json()).then(({data}) => {setPairTradePlotSettings({...pairTradePlotSettings, data: {...data, time: data?.date?.map(x => new Date(x))}})
                            setIsLoading(false);
                        })
                        }}>Update</button>
                    <p style={{alignSelf: "flex-end", marginTop: 5}}>Line thickness:</p>
                    <Input style={{maxWidth:100, alignSelf: "flex-end"}}
                        type="number"
                        step="0.1"
                        value={pairTradePlotSettings?.thickness || 0.0}
                        onChange={(e) => {
                            setPairTradePlotSettings({...pairTradePlotSettings, thickness: e?.target?.value})
                        }}
                    />
                    <h4 style={{marginTop: 40, marginBottom: 5, alignSelf: "flex-end"}}>Statistics:</h4>
                    <table border={1} style={{fontSize: 13, minWidth: "100%", whiteSpace: "nowrap", marginBottom: 20, alignSelf: "flex-end", border: '1px solid rgba(222,222,222,0.8)'}}>
                        <thead>
                            <tr>
                                <th style={{padding: 10}} key={"mean"}>Mean Prem/Disc Diff</th>
                                <th style={{padding: 10}} key={"extreme"}>Extremity Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{
                                    color: 'white',
                                    textAlign: 'center'
                                }} key={"prem_disc_mean"}>{(calculateMean(pairTradePlotSettings?.data?.[0]?.prem_disc_diff)*100.0).toFixed(2)}%</td>
                                <td style={{
                                    color: 'white',
                                    textAlign: 'center'
                                }} key={"extreme_value"}>{(calculateExtremeValue(pairTradePlotSettings?.data?.[0]?.prem_disc_diff, pairTradePlotSettings?.data?.[0]?.prem_disc_diff?.at(-1))*100.0).toFixed(2)}%</td>
                            </tr>
                        </tbody>
                    </table>
                    <p style={{marginBottom: 5, marginTop:20, alignSelf: "flex-end"}}>Extremity Value Analysis:</p>
                    <table border={1} style={{fontSize: 13, minWidth: "100%", whiteSpace: "nowrap", marginBottom: 20, alignSelf: "flex-end", border: '1px solid rgba(222,222,222,0.8)'}}>
                        <thead>
                            <tr>
                                <th style={{padding: 10}} key={"input"}>Input Percentage</th>
                                <th style={{padding: 10}} key={"extreme"}>Extremity Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{
                                    color: 'white',
                                    textAlign: 'center'
                                }} key={"prem_disc_mean"}>
                                    <Input style={{width: "100%", borderRadius: 0}}
                                        type="number"
                                        step="0.001"
                                        value={dynamicExtremityValueInput}
                                        onChange={(e) => {
                                            const inputVal = e.target.value.trim();
                                            setDynamicExtremityValue(inputVal);
                                        }}
                                    />   
                                </td>
                                <td style={{
                                    color: 'white',
                                    textAlign: 'center'
                                }} key={"extreme_value"}>{(calculateExtremeValue(pairTradePlotSettings?.data?.[0]?.prem_disc_diff, dynamicExtremityValueInput)*100.0).toFixed(2)}%</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    </div>
                </Sidebar>
                </main>
            )
        }
}

export default Graphs;