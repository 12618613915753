import React, { useContext, useEffect, useState, useRef } from 'react';
import Context from '../store/context';
import api from '../api/api';
import { BoundedArray } from '../utils';
import { styles } from '../global/styles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sidebar from '../components/sidebar';
import GraphModal from '../components/graph-modal';
import StatArbPositionsModal from '../components/stat-arb-positions-modal';
import EntryProfileModal from '../components/entry-profile-modal';

const Indicators = () => {
  const {user, setUser, globalState} = useContext(Context);
  const MarketdataSocket = useRef(null);
  const isMarketdataReconnecting = useRef(false);
  const isMarketdataWebSocketConnected = useRef(false);
  const [indicatorsData, setIndicatorsData] = useState({});
  const [closeOrderInfo, setCloseOrderInfo] = useState({});
  const [statArbMetadata, setStatArbMetadata] = useState({});
  const [statArbPositions, setStatArbPositions] = useState({});
  const [statArbSinglePositions, setStatArbSinglePositions] = useState({});
  const [statArbEntryProfiles, setStatArbEntryProfiles] = useState({});
  const [premDiscRtd, setPremDiscRtd] = useState({});
  const histPremDiscRtd = useRef({});
  const [openGraphModal, setOpenGraphModal] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [entryProfileModalData, setEntryProfileModalData] = useState({});
  const [openStatArbPositionsModal, setOpenStatArbPositionsModal] = useState(false);
  const [openStatArbEntryProfileModal, setOpenStatArbEntryProfileModal] = useState(false);
  const [statArbPositionsModalData, setStatArbPositionsModalData] = useState({});
  const [closeIndicators, setCloseIndicators] = useState({});
  const isComponentMounted = useRef(true);

  const minTransactionValue = 1600000;

  // Function to establish the WebSocket connection
  const connectAlgoDataWebSocket = () => {  
    const connectWebSocket = () => {
      let heartbeatInterval;
      const newSocket = new WebSocket('wss://trading.tricap.dk/api/ws/marketdata');
  
      newSocket.onopen = () => {
        isMarketdataWebSocketConnected.current = true;
        newSocket.send(JSON.stringify({
          authorization: "4mysupersecret"
        }));
        console.log('Connected to messaging WebSocket');
  
        // Send a heartbeat message at regular intervals
        heartbeatInterval = setInterval(() => {
          if (isMarketdataWebSocketConnected.current) {
            newSocket.send("heartbeat");
          } else {
            clearInterval(heartbeatInterval);
          }
        }, 3000);

        // Subscribe to relevant message channels.
        const subscriptionData = {
          "action": "subscribe",
          "topics": ["indicators", "premDiscRtd"]
        }
        newSocket.send(JSON.stringify(subscriptionData));
  
        // Save marketdata connection variable.
        MarketdataSocket.current = newSocket;

        isMarketdataReconnecting.current = false;
      };
  
      newSocket.onmessage = (event) => {
        const parsedMsg = JSON.parse(event.data);
        let receivedMsg;
        if (typeof parsedMsg === 'string') {
          receivedMsg = JSON.parse(parsedMsg);
        } else {
          receivedMsg = parsedMsg
        }
        if (receivedMsg?.source?.Text === "indicators") {
          setIndicatorsData(receivedMsg);
        } else {
          // Update the representation for most recent timestep.
          setPremDiscRtd(prevState => ({...prevState, ...receivedMsg?.data}));

          // Add most recent timestep to historical variable.
          let updatedHistPremDiscRtd = {...histPremDiscRtd.current}
          for (let ticker in receivedMsg?.data) {
            if (ticker in updatedHistPremDiscRtd) {
              updatedHistPremDiscRtd[ticker].bid.push(receivedMsg?.data?.[ticker]?.[1])
              updatedHistPremDiscRtd[ticker].mid.push(receivedMsg?.data?.[ticker]?.[2])
              updatedHistPremDiscRtd[ticker].ask.push(receivedMsg?.data?.[ticker]?.[3])
            } else {
              updatedHistPremDiscRtd[ticker] = {};
              updatedHistPremDiscRtd[ticker].bid = new BoundedArray(2000);
              updatedHistPremDiscRtd[ticker].mid = new BoundedArray(2000);
              updatedHistPremDiscRtd[ticker].ask = new BoundedArray(2000);
              updatedHistPremDiscRtd[ticker].bid.push(receivedMsg?.data?.[ticker]?.[1])
              updatedHistPremDiscRtd[ticker].mid.push(receivedMsg?.data?.[ticker]?.[2])
              updatedHistPremDiscRtd[ticker].ask.push(receivedMsg?.data?.[ticker]?.[3])
            }
          }
          histPremDiscRtd.current = updatedHistPremDiscRtd;
        }
      };
  
      newSocket.onclose = () => {
        console.log('Websocket connection closed');
        isMarketdataWebSocketConnected.current = false;
        clearInterval(heartbeatInterval);

        // Check if reconnection is already in progress
        if (!isMarketdataReconnecting.current) {
          // Attempt to reconnect every 5 seconds
          setTimeout(() => {
            if (isComponentMounted.current) {
              isMarketdataReconnecting.current = true; // Set the flag

              // Reconnect to websocket.
              connectWebSocket();
            }
          }, 5000);
        }
      };
  
      return newSocket;
    };
  
    return connectWebSocket();
  };

  // Initiate marketdata and orders websocket connection.
  useEffect(() => {
    MarketdataSocket.current = connectAlgoDataWebSocket();

    return () => {
      isComponentMounted.current = false;
      if (MarketdataSocket.current) {
        MarketdataSocket.current.close();
      }
    };
  }, []);

  // Retrieve Stat Arb metadata.
  useEffect(() => {
    api("algos/stat-arb-metadata").then(res => res.json()).then(data => {
      if (data) {
        setStatArbMetadata(data);
      }
    });    
  }, [statArbPositions])

  // Retrieve Stat Arb positions.
  useEffect(() => {
    api("algos/stat-arb-positions").then(res => res.json()).then(data => {
      if (data) {
        setStatArbPositions(data);
      }
    });    
  }, []);

  // Retrieve Stat Arb single positions.
  useEffect(() => {
    api("algos/stat-arb-single-positions").then(res => res.json()).then(data => {
      if (data) {
        setStatArbSinglePositions(data);
      }
    });    
  }, []);

  // Retrieve Stat Arb entry profiles.
  useEffect(() => {
    api("algos/stat-arb-entry-profiles").then(res => res.json()).then(data => {
      if (data) {
        setStatArbEntryProfiles(data);
      }
    });    
  }, []);

  // Construct close indicators.
  useEffect(() => {
    let curCloseIndicators = {};
    for (let key in statArbPositions) {
      if (!(statArbPositions?.[key]?.spread_id in curCloseIndicators)) {
        curCloseIndicators[statArbPositions?.[key]?.spread_id] = {
          'indicator': null
        }
      }
      if (statArbPositions?.[key]?.direction?.toLowerCase() === "buy") {
        if (indicatorsData?.[statArbPositions?.[key]?.spread_id]?.Indicator?.sell_spread[indicatorsData?.[statArbPositions?.[key]?.spread_id]?.Indicator?.sell_spread?.length-1]-statArbPositions?.[key]?.open_spread >= statArbMetadata?.[statArbPositions?.[key]?.spread_id]?.params?.take_profit) {
          curCloseIndicators[statArbPositions?.[key]?.spread_id]['indicator'] = "SELL";
          for (let i=0; i<statArbSinglePositions?.[key]?.length; i++) {
            if (statArbSinglePositions?.[key]?.[i]?.ticker in curCloseIndicators[statArbPositions?.[key]?.spread_id]) {
              curCloseIndicators[statArbPositions?.[key]?.spread_id][statArbSinglePositions?.[key]?.[i]?.ticker] += parseInt(statArbSinglePositions?.[key]?.[i]?.amount);
            } else {
              curCloseIndicators[statArbPositions?.[key]?.spread_id][statArbSinglePositions?.[key]?.[i]?.ticker] = parseInt(statArbSinglePositions?.[key]?.[i]?.amount);
            }
          }

        }
      } else if (statArbPositions?.[key]?.direction?.toLowerCase() === "sell") {
        if (statArbPositions?.[key]?.open_spread-indicatorsData?.[statArbPositions?.[key]?.spread_id]?.Indicator?.buy_spread[indicatorsData?.[statArbPositions?.[key]?.spread_id]?.Indicator?.buy_spread?.length-1] >= statArbMetadata?.[statArbPositions?.[key]?.spread_id]?.params?.take_profit) {
          curCloseIndicators[statArbPositions?.[key]?.spread_id] = "BUY";
          for (let i=0; i<statArbSinglePositions?.[key]?.length; i++) {
            if (statArbSinglePositions?.[key]?.[i]?.ticker in curCloseIndicators[statArbPositions?.[key]?.spread_id]) {
              curCloseIndicators[statArbPositions?.[key]?.spread_id][statArbSinglePositions?.[key]?.[i]?.ticker] += parseInt(statArbSinglePositions?.[key]?.[i]?.amount);
            } else {
              curCloseIndicators[statArbPositions?.[key]?.spread_id][statArbSinglePositions?.[key]?.[i]?.ticker] = parseInt(statArbSinglePositions?.[key]?.[i]?.amount);
            }
          }
        }
      }
    }
    setCloseIndicators(curCloseIndicators);
  }, [statArbMetadata, statArbPositions, indicatorsData]);

  useEffect(() => {
    if (openStatArbPositionsModal) {
      setStatArbPositionsModalData(prevState => ({
        tickers: prevState?.tickers,
        spread_id: prevState?.spread_id,
        positions: Object.fromEntries(
          Object.entries(statArbPositions).filter(([_, innerObject]) => innerObject["spread_id"] === parseInt(prevState?.spread_id))
        )
      }))
    }
  }, [statArbPositions])

  // Implement a ping mechanism to keep the connection alive during idle periods
  useEffect(() => {
    const pingInterval = setInterval(() => {
      if (document.visibilityState === 'visible' && !isMarketdataWebSocketConnected.current) {
        connectAlgoDataWebSocket();
      }
    }, 30000); // Check every 30 seconds

    return () => clearInterval(pingInterval);
  }, []);
    
  return (
    <main style={{
      display: 'flex',
      height: 'calc(100vh - 40px)',
      margin: 0,
      padding: 0,
      overflow: 'hidden'
    }}>

      {/* Graph Modal */}
      {openGraphModal && (
        <GraphModal mid_spread={graphData?.mid_spread} buy_spread={graphData?.buy_spread} sell_spread={graphData?.sell_spread} tickers={graphData?.tickers} title={graphData?.title} xLabel={graphData?.xLabel} yLabel={graphData?.yLabel} histPremDiscRtd={histPremDiscRtd.current} setOpenGraphModal={setOpenGraphModal}/>
      )}

      {/* Stat Arb Positions Modal */}
      {openStatArbPositionsModal && (
        <StatArbPositionsModal spreadId={statArbPositionsModalData?.spread_id} indicatorsData={indicatorsData} statArbPositions={statArbPositionsModalData?.positions} statArbMetadata={statArbMetadata} allStatArbPositions={statArbPositions} allStatArbSinglePositions={statArbSinglePositions} setStatArbPositions={setStatArbPositions} setStatArbSinglePositions={setStatArbSinglePositions} tickers={statArbPositionsModalData?.tickers} setOpenStatArbPositionsModal={setOpenStatArbPositionsModal}/>
      )}

      {/* Entry Profile Modal */}
      {openStatArbEntryProfileModal && (
        <EntryProfileModal spreadId={entryProfileModalData?.spread_id} tickers={entryProfileModalData?.tickers} statArbEntryProfiles={statArbEntryProfiles} setOpenStatArbEntryProfileModal={setOpenStatArbEntryProfileModal}/>
      )}

      <div style={{
        flex: 1,
        overflow: 'hidden',
        border: '4px solid #ccc',
        background: '#303030',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}>
        <Tabs style={{marginTop: 30}}>
          <TabList>
            <Tab>Statistical Arbitrage</Tab>
          </TabList>
          <TabPanel>
            <button className="hover-update" style={{ marginTop: 5, fontSize: 12, display: "flex", justifyContent: "center", alignItems: 'center'}} onClick={() => {
              // Get stat arb spread positions.
              api("algos/stat-arb-positions").then(res => res.json()).then(data => {
                if (data) {
                  setStatArbPositions(data);
                }
              });

              // Get stat arb spread individual ticker positions.
              api("algos/stat-arb-single-positions").then(res => res.json()).then(data => {
                if (data) {
                  setStatArbSinglePositions(data);
                }
              });
            }}>Import Positions</button>
            <hr style={{color: "white", marginBottom: 10, borderWidth: 1, marginTop: 10}}/>
            <table style={{
              fontSize: 10,
              whiteSpace: 'nowrap',
              width: 1400,
            }}>
              <thead style={styles.table.header}>
                <tr style={{fontSize: 18}}>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Tickers</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Z-Score (Buy/Sell)</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Indicator</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Open/Close</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Spread (Buy/Sell)</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Lookback Period</th>
                   <th style={{
                    border: 0,
                    padding: 10
                  }}>Margin</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Entry Z-Score</th>
                  <th style={{
                    border: 0,
                    padding: 10
                  }}>Positions</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(indicatorsData || {}).length > 0 &&
                  Object.entries(indicatorsData).map(([key, value]) => {
                    const rows = [];
                    if (key !== "source") {
                      rows.push(
                      <tr key={key} className="hover:bg-gray-700" style={{fontSize: 12}}>
                        {/* Tickers */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{ ...styles.table.algos_table_cell, textAlign: "center", padding: 5 }}
                        >
                          <span className='hover-update' style={{marginTop: 3, float: 'left', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 10, height: 15, backgroundColor: '#226699', cursor: 'pointer', borderRadius: 0}} onClick={e => {
                            e.preventDefault()
                            setCloseOrderInfo(prevState => ({...prevState, [key]: !closeOrderInfo?.[key]}))
                            }}>{!closeOrderInfo?.[key] ? '+' : '-'}</span>
                            <span>{value?.Indicator?.tickers?.join("/")}</span>
                        </td>
                        {/* Z-Score */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{ ...styles.table.algos_table_cell, textAlign: "center", padding: 5 }}
                        >
                          {parseFloat(value?.Indicator?.buy_z_score)?.toFixed(2)}/{parseFloat(value?.Indicator?.sell_z_score)?.toFixed(2)}
                        </td>
                        {/* Indicator */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            backgroundColor:
                            closeIndicators?.[key]?.indicator ? 
                              closeIndicators?.[key]?.indicator === "BUY"
                                ? "green"
                                : "red"
                              :
                              value?.Indicator?.indicator === "HOLD"
                                ? "yellow"
                                : value?.Indicator?.indicator === "BUY"
                                ? "green"
                                : value?.Indicator?.indicator === "SELL"
                                ? "red"
                                : "",
                            color: "black",
                            padding: 5
                          }}
                        >
                          {closeIndicators?.[key]?.indicator ? closeIndicators?.[key]?.indicator : value?.Indicator?.indicator}
                        </td>
                        {/* Open/Close */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{ ...styles.table.algos_table_cell, textAlign: "center", padding: 5 }}
                        >
                          {closeIndicators?.[key]?.indicator ? "Close" : "Open"}
                        </td>
                        {/* Spread (Buy/Sell) */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            marginRight: 5,
                            padding: 5
                          }}
                        >
                          <span className="underline" style={{color: "#6495ED", cursor: "pointer"}} onClick={() => {
                            setGraphData({
                              mid_spread: value?.Indicator?.mid_spread?.map(e => parseFloat((e*100)?.toFixed(2))),
                              buy_spread: value?.Indicator?.buy_spread?.map(e => parseFloat((e*100)?.toFixed(2))),
                              sell_spread: value?.Indicator?.sell_spread?.map(e => parseFloat((e*100)?.toFixed(2))),
                              tickers: value?.Indicator?.tickers,
                              title: "Spread",
                              xLabel: "Timestep",
                              yLabel: "Spread (%)"
                            })
                            setOpenGraphModal(true);
                          }}>{`${parseFloat(value?.Indicator?.buy_spread[value?.Indicator?.buy_spread?.length - 1]*100).toFixed(2)}%`}/{`${parseFloat(value?.Indicator?.sell_spread[value?.Indicator?.sell_spread?.length - 1]*100).toFixed(2)}%`}</span>
                        </td>
                        {/* Lookback Period */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            marginRight: 5,
                            padding: 5
                          }}
                        >{statArbMetadata?.[key]?.params?.lookback}</td>
                        {/* Margin */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            marginRight: 5,
                            padding: 5
                          }}
                        >{`${parseFloat(statArbMetadata?.[key]?.params?.take_profit*100).toFixed(2)}%`}</td>
                        {/* Entry threshold */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            marginRight: 5,
                            padding: 5
                          }}
                        >
                          <span className="underline" style={{color: "#6495ED", cursor: "pointer"}} onClick={() => {
                            setEntryProfileModalData({
                              'spread_id': key,
                              'tickers': value?.Indicator?.tickers
                            })
                            setOpenStatArbEntryProfileModal(true);
                          }}>{statArbMetadata?.[key]?.params?.entry_threshold}</span>
                          </td>
                        {/* Positions */}
                        <td
                          className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                          style={{
                            ...styles.table.algos_table_cell,
                            textAlign: "center",
                            marginRight: 5,
                            padding: 5
                          }}
                        >
                          <span className="underline" style={{color: "#6495ED", cursor: "pointer"}} onClick={() => {
                            setStatArbPositionsModalData({
                              tickers: value?.Indicator?.tickers,
                              spread_id: key,
                              positions: Object.fromEntries(
                                Object.entries(statArbPositions).filter(([_, innerObject]) => innerObject["spread_id"] === parseInt(key))
                              )
                            })
                            setOpenStatArbPositionsModal(true);
                          }}>View</span>
                        </td>
                      </tr>)
                      if (closeOrderInfo?.[key]) {
                        rows.push(
                          <tr style={{ height: 25, fontSize: 10 }}>
                            <td colSpan={9} style={{ textAlign: 'center' }}>
                              <table className="border border-solid border-gray-300/[.45]" style={{ ...styles.table.table, margin: '0 auto', border: '', padding: 0, width: 600 }}>
                                <thead style={styles.table.header}>
                                  <tr style={{fontSize: 14}}>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Ticker</th>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Direction</th>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Prem/Disc</th>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Price</th>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Amount</th>
                                  <th style={{
                                    border: 0,
                                    padding: 10
                                  }}>Ratio</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {value?.Indicator?.tickers.map((ticker, index) => (
                                  <tr className="hover:bg-gray-700" key={index}>
                                    {/* Ticker */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "center",
                                        marginRight: 5,
                                        padding: 5
                                      }}
                                    >
                                      {ticker}
                                    </td>
                                    {/* Direction */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "center",
                                        backgroundColor:
                                          closeIndicators?.[key]?.indicator ? 
                                            closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                            ? "green"
                                            : closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                            ? "red"
                                            : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                            ? "red"
                                            : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                            ? "green" : ""
                                          :
                                          value?.Indicator?.indicator === "HOLD"
                                            ? "yellow"
                                            : value?.Indicator?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                            ? "green"
                                            : value?.Indicator?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                            ? "red"
                                            : value?.Indicator?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                            ? "red"
                                            : value?.Indicator?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                            ? "green"
                                            : "",
                                        color: "black",
                                        padding: 5
                                      }}
                                    >{
                                      closeIndicators?.[key]?.indicator ? 
                                        closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? "BUY"
                                        : closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? "SELL"
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? "SELL"
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? "BUY" : "HOLD"
                                      :
                                        value?.Indicator?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        "BUY" :
                                        value?.Indicator?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        "SELL" :
                                        value?.Indicator?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        "SELL"
                                        : value?.Indicator?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        "BUY" : "HOLD"
                                    }</td>
                                    {/* Prem/Disc */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "right",
                                        marginRight: 5,
                                        padding: 5
                                      }}
                                    >{
                                      // Identify if we want to use 25% (for Sell) or 75% (for Buy) of Bid/Ask prem/disc spread.
                                      closeIndicators?.[key]?.indicator ? 
                                        closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2)*100)?.toFixed(2)}%`
                                        : closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2)*100)?.toFixed(2)}%`
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2)*100)?.toFixed(2)}%`
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2)*100)?.toFixed(2)}%` : "HOLD"
                                      :
                                        value?.Indicator?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2)*100)?.toFixed(2)}%` :
                                        value?.Indicator?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2)*100)?.toFixed(2)}%` :
                                        value?.Indicator?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2)*100)?.toFixed(2)}%`
                                        : value?.Indicator?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        `${(((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2)*100)?.toFixed(2)}%` : ""
                                    }</td>
                                    {/* Price */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "right",
                                        marginRight: 5,
                                        padding: 5
                                      }}
                                    >{
                                      // Calculate price using 25% (for Sell) or 75% (for Buy) of Bid/Ask prem/disc spread.
                                      closeIndicators?.[key]?.indicator ? 
                                        closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[0]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        )
                                        : closeIndicators?.[key]?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[0]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        )
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0
                                        ? ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[0]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        )
                                        : closeIndicators?.[key]?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0
                                        ? ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[0]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        ) : ""
                                      :
                                        value?.Indicator?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[0]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        ) :
                                        value?.Indicator?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[0]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        ) :
                                        value?.Indicator?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                        ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[0]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        )
                                        : value?.Indicator?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                        ((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[0]))?.toLocaleString(
                                          undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                        ) : ""
                                    }</td>
                                    {/* Amount */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "right",
                                        marginRight: 5,
                                        padding: 5
                                      }}
                                    >{
                                        closeIndicators?.[key]?.indicator ?
                                        Math.abs(closeIndicators?.[key]?.[ticker])
                                        :
                                        // Calculate amount based on price using 25% (for Sell) or 75% (for Buy) of Bid/Ask prem/disc spread.
                                        value?.Indicator?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                          Math.abs(Math.round(((statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[key]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString() :
                                        value?.Indicator?.indicator === "BUY" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                          Math.abs(Math.round(((statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[key]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString() :
                                        value?.Indicator?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                                          Math.abs(Math.round(((statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[key]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[1]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString()
                                        : value?.Indicator?.indicator === "SELL" && statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                                          Math.abs(Math.round(((statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[key]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+((parseFloat(premDiscRtd?.[ticker]?.[2])+parseFloat(premDiscRtd?.[ticker]?.[3]))/2))*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString() : ""
                                      }</td>
                                    {/* Ratio */}
                                    <td
                                      className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                                      style={{
                                        ...styles.table.algos_table_cell,
                                        textAlign: "right",
                                        marginRight: 5,
                                        padding: 5
                                      }}
                                    >{parseFloat(statArbMetadata?.[key]?.basket?.ratios[statArbMetadata?.[key]?.basket?.tickers?.indexOf(ticker)]).toFixed(2)}</td>
                                  </tr>
                                ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )
                      }
                    }
                    return rows;
                  })}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>
    </main>
  );
}

export default Indicators;